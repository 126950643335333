import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import { Link } from "gatsby"
import components from '../components/MDXComponents'
import { formatDate } from "../components/data-control"
import TocBase from "../components/toc"
import ChartWithCategory from "../components/chart-with-category"

export default function RemarkQa({ data }) {
  const post = data.mdx
  const category = post.fields.category

  const Toc = ({color}) => <TocBase items={post.tableOfContents.items} color={color} />
  const shortcodes = { Toc, ...ChartWithCategory({category}), ...components }

  return (
    <Layout slug={post.fields.slug} category={category} showCharm={true} {...data.meta.nodes[0]} metaReplace={post.frontmatter}>
      <div className="c-page-title c-page-title-qa-detail">
        <div className="c-page-title-qa-detail-inner">
          {post.frontmatter.heading ? 
          <h1 dangerouslySetInnerHTML={{__html : post.frontmatter.heading}} /> : 
          <h1>{post.frontmatter.title}</h1>
          }
        </div>
      </div>

      <div className="p-qa-detail">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </div>

      <div className="c-update">{formatDate(post.frontmatter.date)} 更新</div>

      {data.allMdx.nodes.length > 0 && (
      <div className="p-latest-list p-latest-list-qa">
        <h2>他のQ&amp;Aも読む</h2>

        <div className="c-qa-nav">
          <ul>
            {data.allMdx.nodes.map((data) => (
              <li><Link to={data.fields.slug}>{data.frontmatter.title}</Link></li>
            ))}
          </ul>
        </div>

        <Link to={`/${category}`} className="c-button c-button-qa">歯科衛生士Q&amp;A 一覧</Link>
      </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($category: String!, $slug: String!) {
    meta: allMeta(filter: {slug: {eq: "/qa/001/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date
        title
        heading
        description
        tags
        organization
        position
        interviewee
        interviewee_furigana
      }
      fields {
        slug
        category
      }
      tableOfContents(maxDepth: 3)
    }
    allMdx(
      sort: {fields: [frontmatter___date, fields___slug], order: [DESC, ASC]}
      filter: {fields: {category: {eq: $category}, slug: { ne: $slug }}}
      limit: 6
    ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
  }
`